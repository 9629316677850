import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function CounterDown({
	scrollToPayment,
	date,
	text,
	textMobile,
	colorStartButton,
	colorEndButton,
	// btnText = 'Inscreva-se',
	btnText,
}) {
	const theme = useTheme();
	const classes = useStyles();
	const [isOpen, setIsOpen] = useState(true);

	const [timeLeft, setTimeLeft] = useState();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl'));

	useEffect(() => {
		if (date) {
			const timer = setInterval(() => {
				setTimeLeft(calculateTimeLeft());
			}, 1000);
			return () => clearInterval(timer);
		}
	}, [date]);

	function calculateTimeLeft() {
		const targetDate = new Date(date);
		const now = new Date();
		const difference = targetDate - now;

		let timeLeft = {};

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		} else {
			timeLeft = {
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0,
			};
		}

		return timeLeft;
	}

	const isCountdownZero =
		timeLeft?.days === 0 && timeLeft?.hours === 0 && timeLeft?.minutes === 0 && timeLeft?.seconds === 0;

	if (isCountdownZero) {
		return null;
	}

	return (
		<Grid
			container
			direction={matchesMobile ? 'column' : 'row'}
			justifyContent="center"
			alignItems="center"
			className={classes.container}
			style={{
				display: isOpen ? 'flex' : 'none',
			}}
		>
			<Grid
				item
				xs={12}
				className={classes.countdown}
				style={{ position: 'relative' }}
			>
				<Grid
					container
					wrap={'nowrap'}
					justifyContent={'space-between'}
					alignItems="center"
					// direction={matchesMobile ? 'column' : 'row'}
				>
					{matchesTablet ? null : (
						<Grid
							item
							container
						>
							<Typography
								fontSize={matchesMobile ? 17 : 24}
								fontFamily="Manrope"
								fontWeight={500}
								textAlign={matchesDownXl ? 'left' : 'center'}
								className={classes.text}
								style={{ marginRight: matchesMobile ? '0.5rem' : '2rem', whiteSpace: 'normal' }}
							>
								{matchesMobile ? textMobile : text}
							</Typography>
						</Grid>
					)}
					{/* Desconto 5% */}
					{/* <Grid
						item
						style={
							matchesTablet
								? {
										border: '2px solid #FF5F45',
										borderRadius: 16,
										background: `linear-gradient(180deg, #363636 0%, #000000 100%)`,
										padding: '1rem 1.5rem',
								  }
								: {
										position: 'absolute',
										bottom: '-35%', // Ajuste a posição vertical para o transbordo
										left: '45%',
										border: '2px solid #FF5F45',
										padding: '1rem 1.5rem',
										borderTopRightRadius: 16,
										borderTopLeftRadius: 16,
										background: `linear-gradient(180deg, #363636 0%, #000000 100%)`,
								  }
						}
					>
						<Typography
							className="gradient-text-trilha-r1-residencia"
							fontFamily={'Manrope'}
							fontWeight={800}
							fontSize={'0.85rem'}
						>
							<p
								className="gradient-text-trilha-r1-residencia"
								style={{
									fontSize: '3rem',
									fontWeight: '800',
									fontFamily: 'Manrope',
									display: 'inline',
									lineHeight: 1,
								}}
							>
								05
							</p>{' '}
							%
						</Typography>
						<Typography
							className="gradient-text-trilha-r1-residencia"
							fontFamily={'Manrope'}
							fontWeight={500}
							fontSize={'0.75rem'}
						>
							de desconto
						</Typography>
					</Grid> */}
					{/* Temporizador e Botão */}
					<Grid
						item
						container
						// justifyContent={matchesDownXl ? 'flex-end' : 'center'}
						justifyContent={'center'}
						alignItems={'center'}
						columnGap={2}
						style={{
							marginRight: '1rem',
						}}
					>
						<Grid
							item
							// xs={12}
							container
							fontSize={matchesMobile ? 6 : 12}
							fontFamily="Outfit"
							justifyContent="center"
							className={classes.countdownItem}
						>
							<Grid
								item
								container
								style={{
									width: 'fit-content',
								}}
							>
								{timeLeft?.days !== 0 && (
									<>
										<Grid
											item
											container
											alignItems={'flex-end'}
											className={classes.countdownNumber}
											fontSize={matchesMobile ? 25 : 45}
											width={'fit-content'}
										>
											{timeLeft?.days?.toString()?.padStart(2, '0')}
										</Grid>
										<Grid
											item
											container
											marginBottom={matchesMobile ? 0 : 1.6}
											marginRight={matchesMobile ? 2 : 0.8}
											marginLeft={matchesMobile ? 0.75 : 1}
											alignItems={'flex-end'}
											className={classes.countdownText}
											width={matchesMobile ? 25 : 50}
										>
											{timeLeft?.days <= 1 ? 'Dia' : 'Dias'}
										</Grid>
									</>
								)}
								<Grid
									item
									container
									alignItems={matchesMobile ? 'flex-end' : 'center'}
									className={classes.countdownNumber}
									fontSize={matchesMobile ? 25 : 45}
									paddingTop={matchesMobile ? 1.5 : 0}
									width={'fit-content'}
								>
									{timeLeft?.hours?.toString()?.padStart(2, '0')}
								</Grid>
								<Grid
									item
									container
									alignItems={matchesMobile ? 'flex-end' : 'center'}
									className={classes.countdownText}
									margin={matchesMobile ? '0 0.25rem' : 0.4}
									marginBottom={matchesMobile ? 0 : 1.5}
									width={'fit-content'}
									fontWeight={800}
									fontSize={32}
								>
									:
								</Grid>
								<Grid
									item
									container
									alignItems={matchesMobile ? 'flex-end' : 'center'}
									className={classes.countdownNumber}
									fontSize={matchesMobile ? 25 : 45}
									width={'fit-content'}
									paddingTop={matchesMobile ? 1.5 : 0}
								>
									{timeLeft?.minutes?.toString()?.padStart(2, '0')}
								</Grid>
								<Grid
									item
									container
									alignItems={matchesMobile ? 'flex-end' : 'center'}
									className={classes.countdownText}
									margin={matchesMobile ? '0 0.25rem' : 0.4}
									marginBottom={matchesMobile ? 0 : 1.5}
									width={'fit-content'}
									fontWeight={800}
									fontSize={32}
								>
									:
								</Grid>
								<Grid
									item
									className={classes.countdownNumber}
									fontSize={matchesMobile ? 25 : 45}
									width={'fit-content'}
									paddingTop={matchesMobile ? 1.5 : 0}
								>
									{timeLeft?.seconds?.toString()?.padStart(2, '0')}
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<button
								onClick={() => scrollToPayment()}
								className={classes.optionButton}
								color="secondary"
								style={{
									background: `linear-gradient(180deg, ${colorStartButton} 0%, ${colorEndButton} 100%)`,
									color: 'white',
									fontWeight: 700,
									height: 35,
									padding: '0px 25px',
								}}
							>
								{btnText}
							</button>
						</Grid>
					</Grid>
				</Grid>
				<div
					style={{
						position: 'absolute',
						right: matchesTablet ? -5 : 5,
						top: -8,
						width: 16,
						height: 16,
						cursor: 'pointer',
					}}
					onClick={() => setIsOpen(false)}
				>
					<img
						src={require('../../R3/assets/icon-close-grey.png')}
						alt=""
					/>
				</div>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		position: 'fixed',
		bottom: 0,
		width: '100%',
		background: `linear-gradient(180deg, #565656 0%, #1F1F1F 100%)`,
		color: '#fff',
		padding: '1rem 0.5rem',
		zIndex: 1000,
		fontFamily: 'Manrope',
		backdropFilter: 'blur(20px)',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column !important',
			alignItems: 'center !important',
			padding: '1rem !important',
		},
	},
	countdown: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column !important',
			alignItems: 'center !important',
		},
	},
	text: {
		marginBottom: theme.spacing(1),
		textAlign: 'start',
		marginLeft: '50px !important',
		flex: 1,
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			marginBottom: theme.spacing(2),
			marginLeft: '0px !important',
		},
	},
	countdownItem: {
		width: 'fit-content !important',
		// justifyContent: 'space-between !important',
		[theme.breakpoints.down('sm')]: {
			alignItems: 'center !important',
			width: '100% !important',
		},
	},
	countdownNumber: {
		fontSize: '37px',
		margin: theme.spacing(0.5),
		fontWeight: 800,
	},
	countdownText: {
		fontSize: '14px',
		margin: theme.spacing(0.5),
		textAlign: 'center',
	},
	optionButton: {
		cursor: 'pointer',
		borderRadius: '24px !important',
		width: 'fit-content',
		fontFamily: 'Manrope',
		fontSize: '15px !important',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2),
			padding: '0 40px 0 40px !important',
			marginRight: '0px !important',
		},
	},
}));
