import React from 'react';

const LoaderSpinner = () => {
	const spinnerStyle = {
		width: '50px',
		height: '50px',
		border: '5px solid #f3f3f3',
		borderTop: '5px solid red',
		borderRadius: '50%',
		animation: 'spin 1s linear infinite',
	};

	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
			<div style={spinnerStyle}></div>
		</div>
	);
};

// Add keyframes animation for the spinner
const spinnerAnimationStyle = document.createElement('style');
spinnerAnimationStyle.innerHTML = `
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}`;
document.head.appendChild(spinnerAnimationStyle);

export default LoaderSpinner;
