import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

export default function BoxInfo({ title, description }) {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (<Grid
			item
			container
			rowGap={3}
		>
			<Typography
				fontFamily={'Manrope'}
				fontWeight={800}
				color={'#FCFCFC'}
				style={{ fontSize: matchesMobile ? '1.25rem' : '1.5rem' }}
			>
				{title}
			</Typography>
			<Typography fontFamily={'Poppins'}
						fontWeight={400}
						color={'#F5F5F5'}
						style={{ fontSize: '0.875rem' }}>{description}</Typography>
		</Grid>);
}
