export default function RedirectStoreButton({ handleRedirect, img, store, classes }) {
	return (
		<button
			className={classes.redirectStoreBtn}
			onClick={() => handleRedirect(store)}
		>
			<img
				src={img}
				alt=""
			/>
		</button>
	);
}
