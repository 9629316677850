import React from 'react';
import { Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DefaultButton from '../Buttons/DefaultButton';

export default function FirstSectionFullScreen2024({
	backgroundImageUrl,
	logoUrl,
	buttonText,
	executeScroll,
	firstTextPaper,
	type,
	buttonProps,
	imageProps,
}) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<Grid
			container
			alignContent="center"
			justifyContent="center"
			className={classes.firstSection}
			style={
				matchesMobile
					? { backgroundImage: `url(${backgroundImageUrl})`, backgroundSize: 'cover' }
					: matchesTablet
					? { marginTop: 80, backgroundImage: `url(${backgroundImageUrl})` }
					: {
							backgroundImage: `url(${backgroundImageUrl})`,
							backgroundSize:
								(window.location.pathname === '/trilha-r1-2025' ||
									window.location.pathname === '/trilha-r1-2025-teste-hwm') &&
								'cover',
					  }
			}
		>
			<Grid
				item
				xs={12}
				container
				alignContent="center"
				rowGap={matchesTablet ? (window.location.pathname === '/trilha-r1-2025' ? 4 : 2) : 0}
				style={{ maxWidth: '1410px', height: '100%' }}
			>
				<Grid
					item
					xs={12}
					md={6}
					container
					direction={'column'}
					justifyContent={'center'}
					alignContent={'center'}
					rowGap={3}
					style={{ height: 'fit-content' }}
					// onClick={buttonText ? null : isExtensivo24 ? () => executeScroll() : null}
				>
					<img
						src={logoUrl}
						alt=""
						className={classes.logo}
						// style={
						// 	imageProps
						// 		? {
						// 				width: imageProps.width,
						// 				left: imageProps.left,
						// 				top: imageProps.top,
						// 		  }
						// 		: null
						// }
					/>
					{buttonText ? (
						<Grid
							item
							xs={8}
							sm={6}
							container
							justifyContent="center"
							className={`${type === 'red' ? 'residencia' : type === 'green' ? 'revalida' : null}`}
							// style={
							// 	buttonProps
							// 		? {
							// 				bottom: matchesMobile
							// 					? 'unset'
							// 					: matchesTablet
							// 					? '2.5em'
							// 					: matchesXl
							// 					? '5.5em'
							// 					: buttonProps.bottom,
							// 				left: matchesLg ? '3em' : buttonProps.left,
							// 		  }
							// 		: null
							// }
						>
							<DefaultButton
								variant="outlined"
								color={buttonProps ? 'primary' : 'secondary'}
								className={`${classes.btnFirstSection} ${
									buttonProps ? buttonProps.type : 'grid-btn-subscribe btn'
								} ${buttonProps ? 'white-btn-secondary' : 'btn-secondary'} poppins`}
								onClick={() => executeScroll()}
							>
								{buttonProps?.buttonLogoUrl ? (
									<img
										src={buttonProps.buttonLogoUrl}
										alt=""
										className={classes.logoBtn}
									/>
								) : null}
								&nbsp;{buttonText}
							</DefaultButton>
						</Grid>
					) : null}
				</Grid>
				<Grid
					item
					xs={12}
					md={window.location.pathname === '/trilha-r1-2025' ? 6 : 6}
					container
					justifyContent={
						window.location.pathname === '/migracao-trilha-revalida' ||
						window.location.pathname === '/trilha-r1-2025'
							? 'center'
							: 'flex-start'
					}
					alignContent="center"
					className={classes.firstSectionCard}
				>
					{window.location.pathname === '/migracao-trilha-revalida' ? (
						<iframe
							// width="560"
							width="940"
							// height="315"
							height={matchesMobile ? '500' : matchesLg ? '400' : '529'}
							src="https://player.vimeo.com/video/857680008"
							title="Vimeo video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
							style={{ borderRadius: '20px', width: 'fit-content', border: '2px solid #5f5858' }}
						></iframe>
					) : (
						<Grid
							item
							xs={12}
							md={10}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-end'}
							style={
								window.location.pathname === '/sprint-final-revalida' ||
								window.location.pathname === '/trilha-revalida' ||
								window.location.pathname === '/sprint-final'
									? {
											marginTop: '1rem',
											padding: matchesLg ? 0 : '0 4rem',
									  }
									: matchesMobile && window.location.pathname === '/trilha-r1-2024'
									? { marginBottom: '2rem' }
									: null
							}
						>
							<Paper
								color="primary"
								className={`${classes.firstSectionPaper} ${
									!window.location.pathname === 'trilha-r1-2025' && 'box'
								} box-primary`}
								elevation={4}
							>
								<Grid
									item
									xs={12}
									className={classes.gridItem}
									container
									justifyContent="flex-start"
									alignContent="center"
								>
									{firstTextPaper}
								</Grid>
							</Paper>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	firstSection: {
		backgroundRepeat: 'no-repeat',
		height: 764,
		margin:
			window.location.pathname === '/trilha-r1-2025' || window.location.pathname === '/trilha-r1-2025-teste-hwm'
				? ''
				: '20px auto',
		[theme.breakpoints.down('xl')]: {
			height: 500,
		},
		[theme.breakpoints.down('sm')]: {
			padding: window.location.pathname === '/trilha-r1-2025' ? '6vh 4.25% 6vh !important' : '2vh 5% !important',
			height: 'unset',
			margin: window.location.pathname === '/trilha-r1-2025' ? '75px auto 0' : '80px auto 0',
		},
	},
	btnFirstSection: {
		width: 'fit-content',
		padding: '0.25rem 4rem !important',
		fontSize: '1.425rem !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff',
			'& $logoBtn': {
				filter: 'invert(0%) !important' /* Linha para passar estilo do pai para o filho */,
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
			minHeight: 'unset !important',
			width: '80%',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0.25rem 2rem !important',
			fontSize: '0.9rem !important',
		},
	},
	firstSectionCard: {
		height: '100%',
		position: 'absolute',
		right: '0',
		[theme.breakpoints.down('xl')]: {
			right: '1.5rem',
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset !important',
			right: 'unset !important',
			height: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'unset !important',
			top: 'unset !important',
			margin: window.location.pathname === '/migracao-trilha-revalida' ? '1.5rem 0 !important' : 'unset',
		},
	},
	firstSectionPaper: {
		color: 'unset !important',
		height: '100%',
		borderRadius: '30px !important',
		padding: '1.5rem 1.5em 2.8em',
		paddingTop: 20,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '1rem !important',
		},
	},
	gridItem: {
		padding: '.5em 0',
	},
	firstText: {
		fontSize: '3rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	logo: {
		// position: 'absolute',
		zIndex: 1,
		width:
			window.location.pathname === '/trilha-r1-2025' || window.location.pathname === '/trilha-r1-2025-teste-hwm'
				? '90%'
				: '60%',
		alignSelf: window.location.pathname === '/trilha-r1-2025' ? 'flex-start' : 'center',
		// top: window.location.pathname === '/migracao-trilha-revalida' ? '4.75em' : '12.75em',
		// left: window.location.pathname === '/sprint-final' ? '2em' : '6em',
		// maxWidth: 498,
		// cursor: 'pointer',
		[theme.breakpoints.down('xl')]: {
			// top: '10.75em',
			width: 400,
		},
		[theme.breakpoints.down('lg')]: {
			// top: '5.75em',
			width: 300,
		},
		[theme.breakpoints.down('sm')]: {
			width: window.location.pathname === '/trilha-r1-2025' ? 300 : 250,
			alignSelf: window.location.pathname === '/trilha-r1-2025' && 'center',
			// top: '2.75em',
			// left: '.5em',
		},
	},
	logoBtn: {
		filter: 'invert(100%)',
		height: window.location.pathname === '/migracao-trilha-revalida' ? '1.75rem' : null,
		marginRight: window.location.pathname === '/migracao-trilha-revalida' ? '0.5rem' : null,
		[theme.breakpoints.down('sm')]: {
			width: 25,
		},
		// '&:hover': {
		// 	filter: 'invert(0%) !important',
		// },
	},
}));
