import React, { useState } from 'react';
import { Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../Services/Api';
import DefaultButton from '../Buttons/DefaultButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import R3CMCardBackground from '../../Assets/Extensivo/R3CM/cardBackground.png';
import EXTR120232024CardBackground from '../../Assets/Extensivo/R1/2023-24/cardBackground.png';
import IntlTelInput from 'react-intl-tel-input';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
// import CountDownTimer from '../../Pages/LandingPages/Sale/LDL/components/CountDownTimer';
// import R3CGCardBackground from '../../Assets/Extensivo/R3CG/cardBackground.png';

export default function PriceCardVersion2({
	idTurma,
	/* imageBackground */ logo,
	iconBtn,
	idomedStudents,
	bgImageUrl,
	color,
	discount,
	group,
}) {
	const classes = useStyles(idTurma);
	const theme = useTheme();
	const [turma, setTurma] = useState([]);
	const [lots, setLots] = useState([]);
	const [lotValueLimit, setLotValueLimit] = useState();
	const [isOpenSubscription, setIsOpenSubscription] = useState(false);
	const currentDate = new Date();
	const [waitListVisibility, setWaitListVisibility] = useState('hidden');
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: group ? `${group} LISTA DE ESPERA` : '',
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	const matchesDown361 = useMediaQuery(theme.breakpoints.down(361));
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		Api.get(`turmasabertas/${idTurma}`).then((res) => {
			if (res) setTurma(res[0]);
			const activeLot = res[0]?.lotes?.find((lot, index) => {
				return lot?.id === res[0]?.id_lote_vigente;
				// const startDate = new Date(lot.data_inicio);
				// const endDate = new Date(lot.data_limite);

				// return startDate <= currentDate && currentDate <= endDate;
			});

			if (activeLot) {
				setLots([activeLot]);
				setLotValueLimit(new Date(activeLot?.data_limite).getTime());
			} else {
				const nextLot = res[0]?.lotes?.find((lot, index) => {
					const startDate = new Date(lot.data_inicio);

					return currentDate < startDate;
				});
				// Caso contrário, não há lote ativo, define 'lots' como vazio
				setLots([nextLot]); //
			}
			const opening_subscription_date = new Date(res[0]?.data_inicio_inscricoes).getTime();
			setIsOpenSubscription(currentDate >= opening_subscription_date);
		});
	}, [idTurma]);

	const handleOpenWaitList = () => {
		setWaitListVisibility('unset');
	};

	const handleCloseWaitList = () => {
		setWaitListVisibility('hidden');
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	let sendPreInscricao = function () {
		if (!preInscricao.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone || preInscricao.telefone.length < 11) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: 'Sua inscrição na lista de espera está confirmada! Avisaremos assim que a nova turma estiver liberada.',
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							window.location.reload();
						}
					});
				}
			})
		);
	};

	return (
		<Grid
			container
			alignContent={matchesTablet ? 'space-around' : 'center'}
			justifyContent="space-between"
			className={classes.extensivoR1Card}
			style={
				color
					? {
							border: '2px solid black',
							padding: matchesTablet ? '1rem 0' : 0,
							height: discount ? 'unset' : null,
					  }
					: null
			}
		>
			<Grid
				item
				xs={12}
				md={7}
				container
				justifyContent="center"
				alignContent="center"
				className={classes.gridImg}
				// FIX this
				style={
					bgImageUrl
						? { backgroundImage: `url(${bgImageUrl})`, backgroundSize: 'cover' }
						: {
								backgroundImage: `url(${
									idTurma === '1174' || idTurma === '1157' || idTurma === '1187' || idTurma === '1203'
										? EXTR120232024CardBackground
										: idTurma === '1175'
										? R3CMCardBackground
										: null
									// AINDA NÃO TEM CÓDIGO DE R3CG
									// : idTurma === '1176'
									// ? R3CGCardBackground
									// : null
								})`,
						  }
				}
			>
				<img
					src={logo}
					alt="Logo Extensivo R1 2023/2024"
					className={classes.extensivoLogoImg}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={5}
				container
				justifyContent="center"
				alignContent="center"
				className={color ? classes.gridPriceInfoBlack : classes.gridPriceInfo}
				style={
					idomedStudents || turma.encerrada
						? { padding: '1em 0' }
						: discount
						? { padding: matchesTablet ? 0 : '1.5rem 0' }
						: window.location.pathname === '/sprint-final-revalida'
						? { padding: '1rem' }
						: null
				}
				gap={window.location.pathname === '/sprint-final-revalida' ? 1 : 0}
			>
				{idomedStudents ? (
					<Grid
						item
						container
						alignContent="center"
						style={
							matchesMobile
								? { width: '100%' }
								: { width: idomedStudents ? '75%' : '70%', marginBottom: 20 }
						}
					>
						{turma.encerrada ? (
							<>
								<Typography
									color={color ? 'secondary' : 'primary'}
									align="left"
									className={`${classes.subscriptionInactiveText} roboto-condensed`}
								>
									INSCRIÇÕES ENCERRADAS
								</Typography>
							</>
						) : (
							<>
								<Grid
									item
									container
									alignContent={'center'}
									gap={1}
								>
									<Typography
										className={`${classes.disclaimerText} poppins`}
										color="primary"
									>
										Liberação do acesso completo alunos IDOMED*
									</Typography>
								</Grid>
								<Typography
									color={color ? 'secondary' : 'primary'}
									className={classes.priceTextWhite}
								>
									R$ {turma.valor?.toLocaleString('pt-BR')}
									<sup style={{ fontSize: '61%' }}>,00</sup>
								</Typography>
								<Typography className={classes.priceTextGrey}>
									ou até{' '}
									{turma?.parcelas && turma.parcelas.length > 0
										? turma.parcelas[turma.parcelas.length - 1].qtd
										: ''}
									x de R$
									{turma?.parcelas && turma.parcelas.length > 0
										? turma.parcelas[turma.parcelas.length - 1].valor
										: ''}
								</Typography>
								{/* <strong className={classes.discount}>
									50% de desconto exclusivo para alunos IDOMED
								</strong> */}
							</>
						)}
					</Grid>
				) : (
					<form
						className="form-control"
						action="javascript:;"
					>
						<Grid
							item
							container
							justifyContent={'center'}
						>
							<Grid
								item
								container
								justifyContent={turma.encerrada ? 'center' : 'flex-start'}
								alignContent="center"
								rowGap={2}
								style={
									matchesMobile
										? { width: turma.encerrada ? null : '100%' }
										: turma.encerrada
										? { width: '70%', margin: '2rem 0' }
										: {
												width: '80%',
												marginBottom:
													window.location.pathname === '/sprint-final-revalida' &&
													!isOpenSubscription
														? null
														: 20,
										  }
								}
							>
								{turma.encerrada ? (
									<>
										{/* <img
                                            src={require('../../Assets/Img/img-soon-stripe.svg').default}
                                            alt=""
                                            className={classes.soonStripeImg}
                                        /> */}
										<Typography
											color={color ? 'secondary' : 'primary'}
											align="left"
											className={`${classes.subscriptionInactiveText} roboto-condensed`}
										>
											{/* INSCRIÇÕES{!matchesTablet || !matchesMobile ? <br /> : null} ENCERRADAS */}
											{window.location.pathname === '/sprint-final-revalida'
												? 'EM BREVE'
												: 'INSCRIÇÕES ENCERRADAS'}{' '}
										</Typography>
										{/* <Typography
                                            color={'primary'}
                                            align="left"
                                            className={`${classes.text} poppins`}
                                            style={{ margin: '0.25rem 0' }}
                                        >
                                            Quer ter informações quando novas turmas
                                            <br />
                                            estiverem disponíveis? Inscreva-se aqui.
                                        </Typography> */}
										<DefaultButton
											variant="outlined"
											color="secondary"
											className={`${classes.btn} btn`}
											onClick={() => handleOpenWaitList()}
										>
											{matchesMobile ? null : (
												<img
													src={iconBtn}
													alt=""
												/>
											)}
											Avise-me
										</DefaultButton>
									</>
								) : window.location.pathname === '/sprint-final-revalida' &&
								  !isOpenSubscription ? null : (
									<>
										{/* {promotionalValueIsOver && (
                                            <>
                                                {turma?.id_lote_vigente !== 0 && (
                                                    <Grid
                                                        item
                                                        container
                                                        justifyContent={'space-between'}
                                                        wrap={'nowrap'}
                                                        columnGap={matchesMobile ? 1 : 2}
                                                        style={
                                                            matchesMobile
                                                                ? {
                                                                        margin: '-0.8rem auto 1rem',
                                                                        padding: '0.5rem 0.5rem 0',
                                                                        borderRadius: '0 0 8px 8px',
                                                                        backgroundColor: '#ffffff',
                                                                        width: 'fit-content',
                                                                }
                                                                : {
                                                                        backgroundColor: '#ffffff',
                                                                        padding: '0.5rem 0.75rem 0',
                                                                        borderRadius: '0 0 8px 8px',
                                                                        marginRight: '1rem',
                                                                        marginTop: '-1rem',
                                                                        width: 'fit-content',
                                                                        marginBottom: '1rem',
                                                                        marginLeft: matchesTablet
                                                                            ? '0rem'
                                                                            : matchesLaptop
                                                                            ? '-3rem'
                                                                            : matchesLargeScreen
                                                                            ? '0rem'
                                                                            : '5.5rem',
                                                                }
                                                        }
                                                    >
                                                        <Typography
                                                            color={'primary'}
                                                            className="poppins"
                                                            noWrap
                                                            style={{
                                                                fontSize: matchesMobile ? '0.55rem' : '0.8rem',
                                                                alignSelf: matchesMobile ? 'center' : null,
                                                            }}
                                                        >
                                                            <strong>Promoção</strong>
                                                            <br />
                                                            se encerra em
                                                        </Typography>
                                                        <CountDownTimer
                                                            targetDate={lotValueLimit}
                                                            textColor={'primary'}
                                                        />
                                                    </Grid>
                                                )}
                                            </>
                                        )} */}
										<Grid
											item
											container
											style={matchesMobile ? { paddingLeft: '1rem' } : null}
										>
											{turma?.id_lote_vigente !== 0 ||
												(window.location.pathname !== '/sprint-final-revalida' && (
													<Typography
														className={classes.priceTextGrey}
														style={{ width: matchesMobile ? '100%' : null }}
													>
														De:{' '}
														<span style={{ textDecoration: 'line-through' }}>
															{'R$ 2.299,00'}
														</span>
													</Typography>
												))}
											{turma?.id_lote_vigente !== 0 ? (
												<Typography
													color={color ? 'secondary' : 'primary'}
													className={classes.priceTextWhite}
												>
													R${' '}
													{lots[0]?.valor?.toLocaleString('pt-BR', {
														maximumFractionDigits: 2,
													})}
													<sup style={{ fontSize: '61%' }}>,00</sup>
												</Typography>
											) : (
												<Typography
													color={color ? 'secondary' : 'primary'}
													className={classes.priceTextWhite}
												>
													R${' '}
													{turma?.valor?.toLocaleString('pt-BR', {
														maximumFractionDigits: 2,
													})}
													<sup style={{ fontSize: '61%' }}>,00</sup>
												</Typography>
											)}
											<Typography className={classes.priceTextGrey}>
												ou até 12x de R$
												{turma?.id_lote_vigente !== 0
													? (turma?.valor / 12).toLocaleString('pt-BR', {
															maximumFractionDigits: 2,
													  })
													: (lots[0]?.valor / 12).toLocaleString('pt-BR', {
															maximumFractionDigits: 2,
													  })}
											</Typography>
										</Grid>
									</>
								)}
							</Grid>
							{turma.encerrada ? null : (
								<Grid
									item
									container
									alignContent={'center'}
									justifyContent={'center'}
									style={matchesTablet ? null : { height: '4rem' }}
								>
									{window.location.pathname === '/sprint-final-revalida' ? (
										<DefaultButton
											variant="outlined"
											color="secondary"
											className={`${classes.btn} btn button-info-card`}
											component={Link}
											to={
												isOpenSubscription
													? `//hardworkmedicina.com.br/inscricao/${idTurma}`
													: '/live/inscricao-sprint-final-revalida-2024'
											}
										>
											{matchesMobile ? null : (
												<img
													src={iconBtn}
													alt=""
												/>
											)}
											&nbsp; {isOpenSubscription ? 'Inscreva-se' : 'Avise-me'}
										</DefaultButton>
									) : (
										<DefaultButton
											variant="outlined"
											color="secondary"
											className={`${classes.btn} btn button-info-card`}
											component={Link}
											to={`//hardworkmedicina.com.br/inscricao/${idTurma}`}
										>
											{matchesMobile ? null : (
												<img
													src={iconBtn}
													alt=""
												/>
											)}
											&nbsp; Inscreva-se
										</DefaultButton>
									)}
								</Grid>
							)}
							{discount && !turma.encerrada ? (
								<Grid
									item
									container
									// justifyContent={matchesTablet ? 'center' : 'flex-start'}
									alignContent={'center'}
									style={matchesMobile ? { width: '75%' } : { width: '70%' }}
								>
									<Typography
										style={{
											color: 'grey',
											fontSize: matchesDown361 ? '0.65rem' : matchesMobile ? '0.7rem' : '0.8rem',
										}}
									>
										Desconto de 20% para ex-alunos Extensivo Revalida, Sprint Revalida e/ou Arena
										Revalida.*
									</Typography>
								</Grid>
							) : null}
							<Grid
								item
								container
								justifyContent={'center'}
								className={classes.containerWaitList}
								rowGap={1}
								style={{ visibility: waitListVisibility }}
							>
								<Grid
									item
									container
									justifyContent="space-between"
								>
									<Typography
										color="primary"
										className={`${classes.textWaitList} poppins`}
									>
										Inscrever-se na Lista de Espera
									</Typography>
									<CancelRoundedIcon
										onClick={() => handleCloseWaitList()}
										className={classes.cancelIcon}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									container
									justifyContent={matchesTablet ? 'center' : 'flex-start'}
								>
									<TextField
										fullWidth
										type="string"
										name="nome"
										placeholder="Seu nome completo"
										required={true}
										InputProps={{
											className: `${classes.form} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										style={matchesTablet ? { width: '100%' } : { width: '97%' }}
										onChange={(e) =>
											setPreInscricao({
												...preInscricao,
												nome: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									container
									justifyContent={matchesTablet ? 'center' : 'flex-end'}
									alignContent={'center'}
								>
									<IntlTelInput // Componente Input de telefones internacionais
										name="telefone"
										type="telefone"
										preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
										style={{ width: '100%' }}
										inputClassName={`${classes.phoneNumberForm} ${classes.form} ${classes.formHeight}`}
										nationalMode={true}
										onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
											handlePhone(valid, fullNumber)
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									container
									justifyContent={matchesMobile ? 'center' : 'flex-start'}
								>
									<TextField
										fullWidth
										type="email"
										name="email"
										placeholder="Seu melhor e-mail"
										required={true}
										InputProps={{
											className: `${classes.form} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										style={{ width: '100%' }}
										onChange={(e) =>
											setPreInscricao({
												...preInscricao,
												email: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={10}
									lg={8}
									container
									className="residencia"
									style={{ height: 50, padding: '4px 0px' }}
								>
									<DefaultButton
										// type="submit"
										variant="outlined"
										color="secondary"
										className={`${classes.btnWaitList} btn`}
										onClick={() => sendPreInscricao()}
									>
										<img
											src={require('../../Assets/Arena/icon-waitList.svg').default}
											alt=""
											style={{ width: 20 }}
										/>{' '}
										&nbsp; Confirmar inscrição
									</DefaultButton>
								</Grid>
							</Grid>
						</Grid>
					</form>
				)}
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	extensivoR1Card: {
		borderRadius: 13,
		border: '2px solid white !important',
		height: window.location.pathname === '/sprint-final-revalida' ? 'unset' : 252,
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			// width: '90% !important',
		},
	},
	gridImg: {
		height: '100%',
		borderBottomLeftRadius: 15,
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			padding: 25,
			borderRadius: 25,
		},
	},
	extensivoLogoImg: {
		width: 468.51,
		maxHeight: '65%',
		[theme.breakpoints.down('md')]: {
			padding: '0px',
			maxHeight: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0px 10px',
		},
	},
	gridPriceInfo: {
		backgroundColor: '#ffffff !important',
		borderRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '8px 0 ',
		},
	},
	gridPriceInfoBlack: {
		borderBottomRightRadius: 15,
		borderTopRightRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '8px 0 ',
		},
	},
	priceTextStrikeThrough: {
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		textDecoration: 'line-through',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.15rem !important',
		},
	},
	priceTextWhite: {
		fontSize: '4.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '4.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '2.25rem !important',
		},
	},
	priceTextGrey: {
		fontSize: '1.5rem !important',
		color: 'grey !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.95rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '1rem !important',
		},
	},
	btn: {
		width: '70%',
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		backgroundColor: '#00AB55 !important',
		border: '2px solid #fff !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				// minWidth: '72% !important',
				// fontSize: '1.5rem !important',
				// borderWidth: '3px !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '90%',
		},
	},
	discount: {
		fontSize: '1.1rem',
		color: 'red',
		alignSelf: 'center',
		marginTop: '0.5em',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			margin: '0.5em 0',
		},
	},
	disclaimerText: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	subscriptionInactiveText: {
		fontSize: '3.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			margin: '1rem !important',
		},
	},
	soonStripeImg: {
		position: 'absolute',
		top: -18,
		right: -90,
		[theme.breakpoints.down('xl')]: {
			right: -77,
		},
	},
	containerWaitList: {
		width: '100% !important',
		backgroundColor: '#d6cece',
		zIndex: 1,
		borderRadius: 15,
		padding: '0.5rem 1.25rem',
		boxShadow: '0px 0px 30px 0px #000000',
		border: '2px solid #000000',
		position: 'absolute',
		bottom: 0,
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem 1.25rem',
		},
	},
	textWaitList: {
		fontSize: '1.3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
	},
	formHeight: {
		maxHeight: '3em',
	},
	phoneNumberForm: {
		height: '3em',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
	cancelIcon: {
		color: '#000000',
		right: '-15px',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-20px',
			right: '-18px',
		},
	},
	btnWaitList: {
		fontSize: '1rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.025rem !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
}));
