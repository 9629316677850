import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import { useState } from 'react';
import { useEffect } from 'react';
import TestsPaperForm from '../../../../Components/Utils/Forms/TestsPaperForm';

export default function TestsLibrarySubscriptionPage() {
	const classes = useStyles();
	const theme = useTheme();

	const [institutionCode, setInstitutionCode] = useState('');
	// const { code } = useParams();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		const code = getCodeFromURL();
		// console.log(code);
		setInstitutionCode(code);
	}, []);

	function getCodeFromURL() {
		const path = window.location.pathname;
		const regex = /\/materiais\/cadernos-prova-(.*)/;
		const match = path.match(regex);

		if (match && match[1]) {
			const code = match[1].toUpperCase().trim(); // Retorna o código em letras maiúsculas e remove espaços em branco
			return code;
		}

		return null; // Retorna null caso o código não seja encontrado
	}

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={7}
						container
						direction={matchesMobile ? 'column' : 'row'}
						className="residencia"
						style={matchesMobile ? { marginBottom: 8 } : { marginBottom: 16, height: 'fit-content' }}
					>
						<Typography
							className={`${classes.title} gradient-text roboto-condensed`}
							color="secondary"
							// style={matchesTablet ? { marginTop: 16, paddingLeft: 0 } : { paddingLeft: 15 }}
						>
							Confira{' '}
							{window.location.pathname === '/materiais/cadernos-prova-QUADRIX-FMJ'
								? 'a prova'
								: 'as provas antigas'}
							<br />
							da{' '}
							{institutionCode === 'QUADRIX-FMJ'
								? 'QUADRIX - FMJ 24-25'
								: institutionCode === 'USP'
								? institutionCode + '-SP'
								: decodeURI(institutionCode).replace(/\+/g, ' ').toUpperCase()}
						</Typography>
						{window.location.pathname === '/materiais/cadernos-prova-QUADRIX-FMJ' ? (
							<Typography
								align="left"
								color="secondary"
								className={`${classes.text} poppins`}
							>
								Cadastre-se e tenha acesso à prova de ontem
								<br />
								da QUADRIX, a banca do SUS-SP!
							</Typography>
						) : (
							<Typography
								align="left"
								color="secondary"
								className={`${classes.text} poppins`}
							>
								Inscreva-se no formulário ao lado e tenha acesso <br />
								aos 5 últimos anos de provas do concurso {institutionCode}.
								<br />
								<br />
								Download gratuito!
							</Typography>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						alignContent="flex-end"
						style={{ paddingBottom: 50 }}
					>
						<TestsPaperForm
							group={`MATERIAIS - CADERNOS DE PROVA`}
							title={`Cadastre-se para ter acesso ao caderno de prova ${
								institutionCode === 'QUADRIX-FMJ' ? 'QUADRIX/FMJ' : institutionCode
							}`}
							titleButton="Confirmar cadastro"
							type="residencia"
							urlToRedirect={`/materiais/cadernos-prova-${institutionCode}-download`}
							institution={institutionCode}
						/>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3rem !important',
		lineHeight: '1 !important',
		marginBottom: '2rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
			marginBottom: '1rem !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			marginBottom: '1rem !important',
		},
	},
}));
